.uk-background-primary {
  /*background-color: red;*/
}

.uk-button {
  border-radius: 500px;
}

.uk-navbar-nav > li > a {
  text-transform: none;
}
.uk-navbar-nav > li.uk-active > a {
  font-weight: bold;
}
